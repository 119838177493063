/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import * as React from "react"
import PropTypes from "prop-types"
import Consent from "../components/segment-consent"
import "../css/layout.css"

const LayoutNoHeader = ({ children }) => {
  return (
    <>
      <div>
        <main>{children}</main>
        <Consent />
      </div>
    </>
  )
}

LayoutNoHeader.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutNoHeader
