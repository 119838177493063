import * as React from "react"
import Layout from "../components/layout-no-header-no-footer"
import logo from "../images/logo_black.svg"
import imageCalendar from "../images/calendar.svg"
import { IoPencilSharp } from "react-icons/io5"
import * as queryString from "query-string"

const Danke = () => {
  let fullName = ""
  let year = ""
  let month = ""
  let day = ""
  let result = ""

  // Check if window is defined (so if in the browser or in node.js).
  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    //Track Main Page
    window.analytics.track("Lead", {
      action: "Calendly Termin gebucht",
    })

    const { event_start_time, invitee_full_name } = queryString.parse(
      window.location.search
    )

    fullName = invitee_full_name
    const regex = /\d{4}-\d{2}-\d{2}/

    if (regex.test(event_start_time)) {
      result = event_start_time.match(regex)
      let splitArray = result.toString().split("-")
      year = splitArray[0]
      month = splitArray[1]
      day = splitArray[2]
    } else {
      result = ""
    }
  }
  return (
    <Layout>
      <nav className="mb-5 pt-10 flex justify-center">
        <div className="block md:hidden">
          <a href="/">
            <img src={logo} alt="Logo" width={200} height={39} />
          </a>
        </div>
        <div className="hidden md:block">
          <a href="/">
            <img src={logo} alt="Logo" width={250} height={49} />
          </a>
        </div>
      </nav>
      <div className="w-11/12 mx-auto text-center mt-10 -mb-2 leading-tight">
        <h1>
          Danke <br />
          {fullName} <br />
          für deine Anmeldung!
        </h1>
      </div>
      <section className="mx-auto flex w-11/12">
        <div className="text-center mx-auto relative order-0 lg:order-1 w-full md:w-1/2">
          <div className="relative">
            <img
              className="mx-auto"
              loading="lazy"
              height={512}
              width={512}
              src={imageCalendar}
              alt="Danke"
            />
          </div>
        </div>
      </section>

      <section className="mx-auto bg-white pb-10">
        <div className="w-full md:w-3/4 xl:w-1/2 px-4 md:px-8 mx-auto">
          <div className="mb-12">
            <h2 className="text-gray-800 text-2xl lg:text-3xl font-bold text-center mb-4 md:mb-6">
              Wie sehen die nächsten Schritte aus?
            </h2>
            <p className="text-gray-600 md:text-lg text-center mx-auto">
              Du erhältst eine Kalendereinladung für den{" "}
              <b>
                {day}.{month}.{year}
              </b>{" "}
              mit den Einwahldaten für unsere Videokonferenz per Mail. Innerhalb
              der 30-minütigen Beratung geben wir Dir, durch die Brille eines
              Hackers, den entscheidenden Einblick in die Welt der
              Cyber-Security. Zudem erfährst Du, wie Du Dein Unternehmen wirksam
              vor kriminellen Hackern schützen kannst.
              <br /> <br />
              Damit Du die Informationen erhältst, die für Dich und Dein
              Unternehmen wichtig sind, bitten wir Dich um folgendes:
            </p>
          </div>

          <div className="grid mx-auto w-full md:w-2/3 grid-cols-1 gap-8 md:gap-12 xl:gap-16">
            <div className="flex gap-4 md:gap-6">
              <div className="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-red-500 text-white rounded-lg md:rounded-xl shadow-lg">
                <IoPencilSharp />
              </div>
              <div>
                <h3 className="text-lg md:text-xl font-semibold mb-2">
                  Ansprechpartner
                </h3>
                <p className="text-gray-500 mb-2">
                  Entscheider und IT-Verantwortliche zum Gespräch einladen
                </p>
              </div>
            </div>

            <div className="flex gap-4 md:gap-6">
              <div className="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-red-500 text-white rounded-lg md:rounded-xl shadow-lg">
                <IoPencilSharp />
              </div>
              <div>
                <h3 className="text-lg md:text-xl font-semibold mb-2">
                  Technik
                </h3>
                <p className="text-gray-500 mb-2">
                  Stelle sicher, dass Mikrofon und Kamera vorhanden sind
                </p>
              </div>
            </div>

            <div className="flex gap-4 md:gap-6">
              <div className="w-12 md:w-14 h-12 md:h-14 flex justify-center items-center flex-shrink-0 bg-red-500 text-white rounded-lg md:rounded-xl shadow-lg">
                <IoPencilSharp />
              </div>
              <div>
                <h3 className="text-lg md:text-xl font-semibold mb-2">Fokus</h3>
                <p className="text-gray-500 mb-2">
                  Ziehe Dich für die 30 Minuten aus dem Tagesgeschehen zurück
                </p>
              </div>
            </div>
          </div>
          <p className="text-gray-600 md:text-lg font-semibold text-center mx-auto my-12">
            Du darfst gespannt sein! Wir versorgen Dich mit den für dein
            Unternehmen wichtigsten Informationen im Bereich der
            Cyber-Sicherheit!
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default Danke
